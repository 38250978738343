import { DOCUMENT } from '@angular/common';
import { Component, ChangeDetectionStrategy, ComponentRef, HostBinding, HostListener, Input, SecurityContext, ViewContainerRef, createComponent, EnvironmentInjector, InjectionToken, Provider, inject, ApplicationRef, OnDestroy, input, effect, ExperimentalPendingTasks } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ComponentFactoryService, HTML_PARSER_SERVICE_TOKEN, RouterOrExternalLinkDirective } from '@knorr-bremse-portals/ngx-components';

// https://angular.io/guide/security#sanitization-and-security-contexts
// SecurityContext.NONE actually means any value is allowed
export type AttributeAllowlist = { [attributeName: string]: SecurityContext };

const HTML_ATTRIBUTE_ALLOWLIST_TOKEN = new InjectionToken<AttributeAllowlist>('kb:html-component:attribute-allowlist');

export const DEFAULT_ATTRIBUTE_ALLOWLIST: AttributeAllowlist = {
  href: SecurityContext.URL,
  target: SecurityContext.NONE,
  style: SecurityContext.STYLE,
  'kb-heading': SecurityContext.NONE,
  'direction': SecurityContext.NONE, // kb-standalone-link
  'kb-link': SecurityContext.NONE,
  'kb-list': SecurityContext.NONE,
  'kb-paragraph': SecurityContext.NONE,
};

export function provideHtmlAttributeAllowlist(allowlist: AttributeAllowlist): Provider {
  return {
    provide: HTML_ATTRIBUTE_ALLOWLIST_TOKEN, useValue: allowlist,
  };
}

export function provideDefaultHtmlAttributeAllowlist(): Provider {
  return provideHtmlAttributeAllowlist(DEFAULT_ATTRIBUTE_ALLOWLIST);
}

// We use this guard function instead of instanceof Element to support SSR
function isElementNode(node: ChildNode): node is Element {
  return node.nodeType === Node.ELEMENT_NODE;
}

@Component({
  selector: '[kb-html],kb-html',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
  styles: [':host > div { display: contents; }'],
})
export class HtmlComponent implements OnDestroy {

  private componentFactoryService = inject(ComponentFactoryService);
  private router = inject(Router);
  private domSanitizer = inject(DomSanitizer);
  private environmentInjector = inject(EnvironmentInjector);
  private htmlParserService = inject(HTML_PARSER_SERVICE_TOKEN);
  private globalAttributeAllowlist = inject(HTML_ATTRIBUTE_ALLOWLIST_TOKEN);
  private applicationRef = inject(ApplicationRef);
  private pendingTasks = inject(ExperimentalPendingTasks);
  private document = inject(DOCUMENT);

  // with angular v15 use directive composition API to directly create kbRouterOrExternalLink directive, or other directives
  @HostBinding('attr.kbRouterOrExternalLink')
  private kbRouterOrExternalLink = new RouterOrExternalLinkDirective(this.router, this.domSanitizer);

  private viewContainerRef = inject(ViewContainerRef);

  public html = input.required<string>();

  // https://angular.io/guide/security#sanitization-and-security-contexts
  // SecurityContext.NONE actually means any value is allowed
  @Input() public set attributeAllowlist(allowlist: AttributeAllowlist) {
    this._attributeAllowlist = allowlist;
  }
  public get attributeAllowlist(): AttributeAllowlist {
    return {...this.globalAttributeAllowlist, ...this._attributeAllowlist};
  }
  private _attributeAllowlist?: AttributeAllowlist;

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey', '$event.target'])
  protected onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean, targetElement: Element): boolean {
    return this.kbRouterOrExternalLink.onClick(button, ctrlKey, metaKey, shiftKey, targetElement);
  }

  private activeComponents: ComponentRef<unknown>[] = [];

  public constructor() {
    effect(async () => {
      const parsedDom = this.htmlParserService.parseFromString(`<div>${this.html()}</div>`); // the surrounding div fixes a problem regarding hydration where every other element is copied, this only happens in the real application not in the showcase
      const nodes = parsedDom.body.childNodes;

      const previousComponents = [...this.activeComponents];

      const task = this.pendingTasks.add();

      const childNodes = await this.createComponentTree(nodes);

      this.clearHost(previousComponents);

      const hostElement = this.viewContainerRef.element.nativeElement;
      for (const childNode of childNodes) {
        hostElement.appendChild(childNode);
      }

      task();
    });
  }

  public ngOnDestroy(): void {
    this.activeComponents.every(c => c.destroy());
  }

  private clearHost(components: ComponentRef<unknown>[]): void {
    components.every(c => {
      c.destroy();
      this.activeComponents.splice(this.activeComponents.indexOf(c), 1);
    });

    const hostElement = this.viewContainerRef.element.nativeElement;
    for (const childNode of hostElement.childNodes) {
      childNode.remove();
    }
  }

  private async createComponentTree(templateNodes: NodeListOf<ChildNode>): Promise<ChildNode[]> {
    const createdNodes: ChildNode[] = [];
    for (const templateNode of [...templateNodes]) { // It's important to make the NodeList static, without it nested children are somehow left out
      let createdNode: ChildNode;
      if (isElementNode(templateNode)) {
        let childNodes: ChildNode[] = [];
        if (templateNode.hasChildNodes()) {
          childNodes = await this.createComponentTree(templateNode.childNodes);
        }

        const componentMirror = await this.componentFactoryService.getComponentMirrorByElement(templateNode);
        let createdElement: Element;
        if (componentMirror !== null) {
          const createdComponent = createComponent(componentMirror.type, {
            environmentInjector: this.environmentInjector,
            elementInjector: this.viewContainerRef.injector,
            // We are forced to first create the children because of https://github.com/angular/angular/issues/51421
            projectableNodes: [childNodes], // we only ever target the first <ng-content> therefore inject an array with one element
            hostElement: this.document.createElement(templateNode.tagName),
          });

          createdElement = createdComponent.location.nativeElement;

          this.forEachAttribute(templateNode, (attribute) => {
            let name = attribute.name.toLowerCase();
            const isJsonAttributeValue = attribute.name.startsWith('[') && attribute.name.endsWith(']');
            if (isJsonAttributeValue) {
              name = name.substring(1, name.length - 1);
            }
            const input = componentMirror.inputs.find(({propName}) => propName.toLowerCase() === name);
            if (input !== undefined) {
              let value: unknown;
              if (isJsonAttributeValue) {
                value = JSON.parse(attribute.value);
              } else {
                value = attribute.value;
              }
              createdComponent.setInput(input.propName, value);
              return;
            }
            const selector = componentMirror.selector;
            if (selector.includes(`[${attribute.name}]`) || selector.includes(`[${attribute.name}=`)) {
              return;
            }

            this.copySanitizedAttribute(attribute, createdElement);
          });

          // Cannot use .insert https://angular.dev/errors/NG0503
          this.applicationRef.attachView(createdComponent.hostView);
          this.activeComponents.push(createdComponent);

        } else {
          // clone the node except for attributes to be able to later add them with a allowlist and sanitized
          createdElement = this.document.createElement(templateNode.tagName);
          for (const childNode of childNodes) {
            createdElement.appendChild(childNode);
          }

          this.forEachAttribute(templateNode, (attribute) => {
            this.copySanitizedAttribute(attribute, createdElement);
          });
        }

        createdNode = createdElement;
      } else {
        createdNode = this.document.createTextNode(templateNode.textContent || '');
      }

      createdNodes.push(createdNode);
      templateNode.remove();
    }
    return createdNodes;
  }

  private forEachAttribute(element: Element, func: (attribute: Attr) => void): void {
    for (const attribute of element.attributes) {
      func(attribute);
    }
  }

  private copySanitizedAttribute(attribute: Attr, targetElement: Element): void {
    // We need to allow _nghost attributes for server side generation component styles
    if (!this.isAngularAttribute(attribute) && this.attributeAllowlist[attribute.name] === undefined) {
      // component attribute selectors would trigger this warning
      console.warn(`HtmlComponent: Tried to set attribute ${attribute.name}="${attribute.value}", which is not in the allowlist`);
      return;
    }

    const sanitizedAttributeValue = this.attributeAllowlist[attribute.name] !== undefined ? this.domSanitizer.sanitize(this.attributeAllowlist[attribute.name], attribute.value) : '';
    if (sanitizedAttributeValue === null) {
      return;
    }

    targetElement.setAttribute(attribute.name, sanitizedAttributeValue);
  }

  private isAngularAttribute(attribute: Attr): boolean {
    return attribute.name.startsWith('_nghost-') && attribute.value === '' || attribute.name === 'ng-version';
  }

}
